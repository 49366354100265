body {
  background-color: #f0f2f5;
  text-align: center;
}

.title-level-3 {
  margin-top: 0px;
}

.cardContainer {
  width: 75%;
  display: inline-table;
  margin-bottom: 75px;
}

.centered-row {
  margin-top: 15px;
}

.cardContainer .ant-card {
  width: 100%;
}

.finalCardContainer {
  width: 75%;
  display: inline-table;
  margin-bottom: 25px;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  flex-direction: column;
}

.navbar ul {
  border-bottom: none;
  background-color: #f0f2f5;
}

.menu-item {
  margin: 0 25px !important;
  background-color: #f0f2f5;
}

.separator {
  border-left: 1px solid #f0f2f5;
  height: 24px;
  margin: 0 12px;
}

footer {
  position: fixed;
  bottom: 0;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-age-input .ant-input-number {
  width: 100%;
  text-align: center;
}

.custom-age-input .ant-input-number-input {
  text-align: center;
}

.custom-radio-button {
  width: 50%;
}

.custom-radio-section {
  width: 100%;
}

.staff-portal-login-form {
  padding-bottom: 100%;
}

.custom-table-header .ant-table-thead > tr > th {
  background-color: rgb(80, 107, 167); 
  color: white;
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #506BA7 !important;
  color: #ffffff !important;
}

.data-view-row {
  text-align: left;
  margin-top: 20px;
}

.loading {
  text-align: center;
  font-size: 20px;
  margin-top: 50px;
}

.data-card {
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px; /* Adds rounded corners */
  text-align: left;
}

.data-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.statistic-title {
  font-size: 24px;
  color: #000000; /* Adjust color as needed */
  margin-bottom: px;
}

.title-and-value {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Space out the title and value */
  width: 100%; /* Ensure the container spans the full width of its parent */
}

.statistic-value .ant-statistic-content {
  font-weight: 400;
  display: flex;
  align-items: center;
}